import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useProgressBar } from 'components/atoms/ProgressBar';
import { baseURL } from 'constants/appConfig';
import useXccGuidanceMessages from 'hooks/useXccGuidanceMessages';
import {
    DesignInCacheStatus,
    DesignType,
    XccCompare,
    XccSimple,
    useXccCompareQueries,
    useXccPopulateMutation,
} from 'hooks/xccCompareQuery';
import { Path } from 'types/paths.enum';

interface XccCompareUrl {
    projectId: string | undefined;
    firstRevisionId: string;
    secondRevisionId: string;
    designType: DesignType;
    collaborationSpaceId: string;
}

export type XccCompareWithoutCollaborationSpaceId = Omit<XccCompare, 'collaborationSpaceId'>;

export interface XccCompareService {
    processingGuidanceMessageVisible: boolean;
    xccGuidanceMassages: ReactElement;
    processedGuidanceMessageVisible: boolean;
    addRevisions: (
        firstRevision: XccCompare,
        secondRevision: XccCompare,
        options?: AddRevisionsOptions
    ) => void;
    populateCache: (firstRevision: XccCompare, secondRevision: XccCompare) => void;
    isInCache: (revision: XccCompareWithoutCollaborationSpaceId) => boolean;
    isNotInCache: (revision: XccCompareWithoutCollaborationSpaceId) => boolean;
    isProcessing: (revision: XccCompareWithoutCollaborationSpaceId) => boolean;
    getUrl: ({
        projectId,
        firstRevisionId,
        secondRevisionId,
        designType,
        collaborationSpaceId,
    }: XccCompareUrl) => string | undefined;
    isCachedInternal: (project: XccSimple) => boolean;
    isAnyLoading: boolean;
    onClose: () => void;
    openAction: any;
}

export interface XccCompareProcessing extends XccCompare {
    processing: boolean | 'processed';
}

export interface AddRevisionsOptions {
    isFromUrl?: boolean;
    refetchRevisions?: boolean;
}

function compareXccObject(
    data: XccCompareWithoutCollaborationSpaceId,
    firstVersion: XccCompareWithoutCollaborationSpaceId
) {
    return (
        data.projectId === firstVersion.projectId &&
        data.version === firstVersion.version &&
        data.designType === firstVersion.designType
    );
}

const useXccCompareService = (): XccCompareService => {
    const [revisions, setRevisions] = useState<XccCompareProcessing[]>([]);
    const [pair, setPair] = useState<XccCompare[][]>([]);
    const [currentPair, setCurrentPair] = useState<XccCompare[]>([]);
    const [fromUrl, setFromUrl] = useState(false);
    const { setProgressBar } = useProgressBar();

    const navigate = useNavigate();

    const { data: xccCache } = useXccCompareQueries(revisions);
    const { mutate } = useXccPopulateMutation();

    const addRevisions = (
        firstRevision: XccCompare,
        secondRevision: XccCompare,
        options?: AddRevisionsOptions
    ) => {
        const isFromUrl = options?.isFromUrl ?? false;
        const refetchRevisions = options?.refetchRevisions ?? false;
        setFromUrl(isFromUrl);

        if (!isFromUrl) {
            setCurrentPair([firstRevision, secondRevision]);
        }

        const buffer: XccCompareProcessing[] = [];

        const handleRevision = (revision: XccCompare) => {
            if (!revisions.some((existing) => compareXccObject(existing, revision))) {
                buffer.push({ ...revision, processing: false });
            } else if (refetchRevisions) {
                const match = xccCache.find(({ data }) => data && compareXccObject(data, revision));
                match?.refetch();
            }
        };

        handleRevision(firstRevision);
        handleRevision(secondRevision);

        setRevisions((prevRevisions) => [...prevRevisions, ...buffer]);
    };

    const populateCache = (firstRevision: XccCompare, secondRevision: XccCompare) => {
        setPair((prev) => {
            return [...prev, [firstRevision, secondRevision]];
        });
        xccCache.map(({ data }) => {
            if (data?.designInCacheStatus === DesignInCacheStatus.NotInCache) {
                if (compareXccObject(data, firstRevision)) {
                    mutate(firstRevision);
                }
                if (compareXccObject(data, secondRevision)) {
                    mutate(secondRevision);
                }
            }
        });
        setRevisions((prevRevisions) => {
            return prevRevisions.map((prevRevision) => {
                if (compareXccObject(prevRevision, firstRevision)) {
                    return { ...firstRevision, processing: true };
                }
                if (compareXccObject(prevRevision, secondRevision)) {
                    return { ...secondRevision, processing: true };
                }
                return prevRevision;
            });
        });
        displayProcessingMessage(
            [
                { ...firstRevision, processing: true },
                { ...secondRevision, processing: true },
            ],
            [[firstRevision, secondRevision]]
        );
        clearCurrentPair();
    };

    const isInCache = (revision: XccCompareWithoutCollaborationSpaceId) =>
        xccCache.some(({ data }) => {
            if (!data) {
                return false;
            }
            if (data.designInCacheStatus !== DesignInCacheStatus.InCache) {
                return false;
            }
            return compareXccObject(data, revision);
        });

    const isNotInCache = (revision: XccCompareWithoutCollaborationSpaceId) =>
        xccCache.filter((getXccCacheStatusQuery) => {
            if (getXccCacheStatusQuery.isLoading || getXccCacheStatusQuery.isFetching) {
                return false;
            }
            if (!getXccCacheStatusQuery.data) {
                return false;
            }
            if (
                getXccCacheStatusQuery.data.designInCacheStatus !== DesignInCacheStatus.NotInCache
            ) {
                return false;
            }
            return compareXccObject(getXccCacheStatusQuery.data, revision);
        }).length === 1;

    function getProcessedPairs() {
        const inCacheArray = xccCache.filter(
            ({ data }) => data?.designInCacheStatus === DesignInCacheStatus.InCache
        );
        return pair.filter(
            ([firstVersion, secondVersion]) =>
                inCacheArray.filter(({ data }) => {
                    if (!data) {
                        return false;
                    }
                    if (compareXccObject(data, firstVersion)) {
                        return true;
                    }
                    return compareXccObject(data, secondVersion);
                }).length === 2
        );
    }

    const openAction = async () => {
        const processedPairs = getProcessedPairs();
        setPair((prev) => {
            const index = prev.findIndex((pair) => {
                return (
                    compareXccObject(pair[0], processedPairs[0][0]) &&
                    compareXccObject(pair[1], processedPairs[0][1])
                );
            });
            if (index === -1) {
                console.error('XccCompareService: unable to open selected pair');
                return prev;
            }

            return prev.slice(0, index).concat(prev.slice(index + 1, prev.length));
        });
        if (!processedPairs[0][0].projectId) {
            console.error('XccCompareService: projectId is undefined');
            return;
        }
        const removeOpenedPair = processedPairs.slice(1);
        displayProcessedMessage(removeOpenedPair);

        !fromUrl &&
            (await navigateToXcc(
                processedPairs[0][0].projectId,
                processedPairs[0][0].version,
                processedPairs[0][1].version,
                processedPairs[0][0].designType
            ));
    };

    const closeAction = async () => {
        const processedPairs = getProcessedPairs();
        setPair((prev) => {
            const index = prev.findIndex((pair) => {
                return (
                    compareXccObject(pair[0], processedPairs[0][0]) &&
                    compareXccObject(pair[1], processedPairs[0][1])
                );
            });
            if (index === -1) {
                console.error('XccCompareService: unable to close selected pair');
                return prev;
            }

            return prev.slice(0, index).concat(prev.slice(index + 1, prev.length));
        });
        const removeOpenedPair = processedPairs.slice(1);
        displayProcessedMessage(removeOpenedPair);
    };

    const {
        xccGuidanceMessages,
        isProcessedGuidanceMessageVisible,
        isProcessingGuidanceMessageVisible,
        setProcessedGuidance,
        setProcessingGuidance,
    } = useXccGuidanceMessages({
        openAction: openAction,
        closeAction: closeAction,
    });

    function getProcessingMessage(processingPairs: XccCompare[][]) {
        return processingPairs.map(
            ([firstVersion, secondVersion], index) =>
                `${index > 0 ? ' ' : ''}${firstVersion.projectName} (version ${
                    firstVersion.version
                } vs. ${secondVersion.version})`
        );
    }

    const displayProcessingMessage = (
        updatedRevisions: XccCompareProcessing[] = [],
        updatedPair: XccCompare[][] = []
    ) => {
        const notInCacheArray = xccCache.filter(
            ({ data }) => data?.designInCacheStatus === DesignInCacheStatus.NotInCache
        );

        const selectRevisions = [...revisions, ...updatedRevisions];
        const selectPairs = [...pair, ...updatedPair];

        const processingArray = selectRevisions.filter(
            ({ processing, designType, projectId, version }) => {
                if (!processing) return false;
                const test = notInCacheArray.filter(({ data }) => {
                    return data && compareXccObject(data, { projectId, version, designType });
                });
                return test.length >= 1;
            }
        );

        const processingPairs = selectPairs.filter(([firstVersion, secondVersion]) => {
            const index = processingArray.findIndex((item) => {
                return (
                    compareXccObject(item, firstVersion) || compareXccObject(item, secondVersion)
                );
            });
            return index !== -1;
        });

        processingArray.length === 0
            ? setProcessingGuidance('')
            : setProcessingGuidance(
                  `Processing: ${getProcessingMessage(processingPairs)} project ${
                      processingPairs.length === 1 ? 'view' : 'views'
                  }`
              );
    };

    const displayProcessedMessage = (processedPairs: XccCompare[][]) => {
        processedPairs.length === 0
            ? setProcessedGuidance('')
            : setProcessedGuidance(`Processed: ${getProcessingMessage(processedPairs)[0]}`);
    };

    const getUrl = ({
        projectId,
        firstRevisionId,
        secondRevisionId,
        designType,
        collaborationSpaceId,
    }: XccCompareUrl) => {
        if (!(projectId && firstRevisionId && secondRevisionId && designType)) {
            console.error('XccCompareService: missing required parameters');
            return;
        }

        const url = new URL(baseURL + '/frontend/v1/xcc/compare');
        const params = url.searchParams;
        params.append('collaborationSpaceId', collaborationSpaceId);
        params.append('FirstProjectId', projectId);
        params.append('SecondProjectId', projectId);
        params.append('FirstRevision', firstRevisionId);
        params.append('SecondRevision', secondRevisionId);
        params.append('DesignType', designType.toString());
        return url.toString();
    };

    async function navigateToXcc(
        projectId: string,
        firstRevision: string,
        secondRevision: string,
        designType: DesignType
    ) {
        navigate(
            `${Path.XCC_COMPARE}/${projectId}/${firstRevision}/${secondRevision}/${designType}`
        );
    }

    const clearCurrentPair = () => {
        setCurrentPair([]);
    };

    useEffect(() => {
        if (!fromUrl) {
            displayProcessingMessage();
            displayProcessedMessage(getProcessedPairs());
        }
        xccCache.findIndex(({ data, isLoading }) => !data && isLoading) === -1
            ? setProgressBar(false)
            : setProgressBar(true);
    }, [xccCache]);

    useEffect(() => {
        if (currentPair && currentPair.length === 2) {
            const isPairInCache =
                isInCache(currentPair[0] as XccCompareWithoutCollaborationSpaceId) &&
                isInCache(currentPair[1] as XccCompareWithoutCollaborationSpaceId);
            if (isPairInCache && currentPair[0].projectId) {
                setCurrentPair([]);
                navigateToXcc(
                    currentPair[0].projectId,
                    currentPair[0].version,
                    currentPair[1].version,
                    currentPair[0].designType
                );
            }
        }
    }, [xccCache, currentPair]);

    const isCachedInternal = (project: XccSimple) => {
        return xccCache.some(({ data }) => data && compareXccObject(data, project));
    };

    const isProcessing = (revision: XccCompareWithoutCollaborationSpaceId) => {
        return revisions.some((rev) => compareXccObject(rev, revision) && rev.processing === true);
    };

    return {
        processingGuidanceMessageVisible: isProcessingGuidanceMessageVisible,
        processedGuidanceMessageVisible: isProcessedGuidanceMessageVisible,
        xccGuidanceMassages: xccGuidanceMessages,
        addRevisions,
        isInCache,
        isNotInCache,
        populateCache,
        getUrl,
        isCachedInternal,
        isProcessing,
        isAnyLoading: xccCache.some(({ isLoading }) => isLoading),
        onClose: () => setFromUrl(false),
        openAction,
    };
};

export default useXccCompareService;
